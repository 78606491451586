
const getPorGetPortalNavSchemestal = 'api/Portal/GetPortal';// 获取首页设置（包含营销设置和养车参考）
const getPortalNavSchemes = 'api/Portal/GetPortalNavSchemes';// 获取首页设置方案模板
const updateMerchantPortalNavItem = 'api/Portal/UpdateMerchantPortalNavItem';// 更新导航按钮可选商品设置
const applyPortal = 'api/Portal/ApplyPortal';// 应用首页设置
const getEditList = 'api/GroupActity/GetEditList';// 获取团购活动列表 1反差团购 2老带新成生效团
const getSpecPropItems = 'api/Goods/GetSpecPropItems';// 获取商品指定规格的所有规格项
const getMaintainList = 'api/FreeOrderActity/GetEdit';// 获取免单套餐活动
const editMaintain = 'api/FreeOrderActity/Edit';// 编辑免单套餐活动
const goOnline = 'api/FreeOrderActity/GoOnline';// 上线免单套餐活动

export let constApi = {
    getPorGetPortalNavSchemestal,
    getPortalNavSchemes,
    updateMerchantPortalNavItem,
    applyPortal,
    getEditList,
    getSpecPropItems,
    getMaintainList,
    editMaintain,
    goOnline,
}