import * as echarts from 'echarts/core'
import { BarChart } from 'echarts/charts'
// 引入 canvas 渲染器。按需引入必须引入 SVGRenderer/CanvasRenderer 二选一
import {CanvasRenderer, SVGRenderer} from "echarts/renderers";


// 引入提示框、标题、直角坐标系，数据集，内置数据转换器组件
import { 
    TitleComponent,
    TooltipComponent,
    GridComponent,
    DatasetComponent,
    TransformComponent,
    LegendComponent
} from 'echarts/components'

// 标签自动布局、全局过度动画等特性
import {LabelLayout, UniversalTransition} from "echarts/features";

echarts.use([ 
    BarChart, 
    CanvasRenderer,
    LabelLayout,
    UniversalTransition,
    TitleComponent,
    TransformComponent,
    GridComponent,
    DatasetComponent,
    TooltipComponent,
    CanvasRenderer,
    SVGRenderer,
    LegendComponent
]);


// 导出
export default echarts;