<template>
    <div id="app" :style="{'min-height': pageHeight+'px'}">
        <!-- 这里是Component的渲染位置 -->
        <div class="loader" v-if="$store.state.loaders">
            <vue-loaders-ball-clip-rotate-multiple color="white" scale="1"></vue-loaders-ball-clip-rotate-multiple>
        </div>
        <router-view v-wechat-title="$route.meta.title" />
        <!--<v-foot-nav v-if="$store.state.footerVisible"></v-foot-nav>-->
        <el-dialog title="开启通知"
                   :modal-append-to-body="true"
                   :append-to-body="true"
                   :show-close="false"
                   :destroy-on-close="true"
                   :visible.sync="dialogVisible"
                   custom-class="emp-notice-box"
                   width="75%">
            <div style="display: flex;justify-content: center;">
                <el-image class="center" style="width: 200px; height: 200px; font-size: 120px; color: #eaeaea;margin: 0 auto;text-align: center;"
                          :src="qrCodeUrl"
                          fit="fill">
                    <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </el-image>
            </div>
            <div style="text-align: center; padding-bottom:25px;">
                <div>接收客户业务受理等重要通知</div>
                <div>长按二维码识别开启</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>

    // 引人组件
    //import footNav from '@/components/navigation/FootNav';
    export default {
        name: 'app',
        components: {
            //'v-foot-nav': footNav
        },
        data() {
            return {
                dialogVisible: false,
                qrCodeUrl: '',
                pageHeight: 1080,
            }
        },

        created() {
            var that = this;
            window.addEventListener('message', that.receiveMessageIframePage, false);

            // 刷新页面时加载 sessionStorage 里的信息
            /*if(sessionStorage.getItem("store")){
              this.$store.replaceState(Object.assign({}, this.$store.state,JSON.parse(sessionStorage.getItem("store"))));
            }*/

            // 在页面刷新时将 vuex 里的信息保存到 sessionStorage 里
            /*window.addEventListener("beforeunload",()=>{
              sessionStorage.setItem("store",JSON.stringify(this.$store.state));
            })*/
            /*if(!this.$store.state.footerVisible){
              this.$store.commit('setFooterVisible', true);
            }*/

            // 禁止页面缩放
            document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no');

            //安卓手机强制禁止用户修改微信客户端的字体大小
            if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
                this.handleFontSize();
            } else {
                if (document.addEventListener) {
                    document.addEventListener("WeixinJSBridgeReady", this.handleFontSize, false);
                } else if (document.attachEvent) {
                    document.attachEvent("WeixinJSBridgeReady", this.handleFontSize);
                    document.attachEvent("onWeixinJSBridgeReady", this.handleFontSize);
                }
            }

            // 检查是否开启消息通知
            if (!that.$work_ctx.identity.notice.noticeOpenId) {
                that.getScenesQrcode();
            }

            that.$nextTick(() => {
                that.setPageHeight();
            })
        },

        mounted() {
            // 不知道为啥，body被人写上position:relative，导致所有下拉浮层都错位，只能临时js动态修改样式
            document.getElementsByTagName("body")[0].style.position = "initial";
            // 监听窗口大小变化
            window.addEventListener('resize', this.setPageHeight);
        },
        destroyed() {
            // 组件销毁时移除事件监听器
            window.removeEventListener('resize', this.setPageHeight);
        },
        watch: {
            '$route': 'fetchRoute',


        },

        methods: {
            setPageHeight() {
                this.pageHeight = document.documentElement.clientHeight - 20;
            },

            handleFontSize() {
                // 设置网页字体为默认大小
                WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize': 0 });
                // 重写设置网页字体大小的事件
                WeixinJSBridge.on('menu:setfont', function () {
                    WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize': 0 });
                });
            },

            //// 监听路由变化
            //fetchRoute(){
            //  console.log('路由变化：', this.$route);
            //  const arr = ['Biz', 'Work', 'Profile', 'Acc', 'Setting'];
            //  if(arr.some(x=>x === this.$route.name)){
            //    this.$store.commit('setFooterVisible', true);
            //  }else{
            //    this.$store.commit('setFooterVisible', false);
            //  }
            //}
            receiveMessageIframePage(msg) {
                // console.log('receiveMessageIframePage-->', msg.data);
                if (msg.data == 'open')
                    this.$store.commit('setFooterVisible', false);
                else if (msg.data == 'close')
                    this.$store.commit('setFooterVisible', true);
                else if (msg.data.type == 'jump_link')
                    window.location.href = msg.data.data;
                else if (msg.data.type == 'scrollChange')
                    document.getElementsByTagName("iframe")[0].style.height = msg.data.scrollTop + "px";
            },
            fetchRoute() {
                const arr = ['EquivalentGroupSet', 'DiffPriceGroupSet', 'FreeOrderSet', 'TopRated', 'Preview','ViewSetting','RenewPurchaseActivity'];
                if (arr.some(x => x === this.$route.name)) {
                    this.$store.commit('setSettingNav', false);
                } else {
                    this.$store.commit('setSettingNav', true);
                }
            },

            getScenesQrcode() {
                var that = this;
                var data = {
                    scenes_value: `empnotice:${that.$work_ctx.identity.adminEmployeeId};${that.$work_ctx.identity.agentOpenId}`,
                    actionName: 'QR_STR_SCENE',
                };

                that.$request.post(that.$api.build('/api/mp/getScenesQrcode'), data).then(res => {
                    console.log('/api/mp/getScenesQrcode', res);
                    if (res.data.code != 200) {
                        layer.msg(res.data.message);
                    }

                    that.qrCodeUrl = res.data.data;
                    that.dialogVisible = true;
                }).catch(err => {
                    console.log(err);
                })
            },
        }

    }

</script>

<style lang="scss">
    #app {
        /*padding-bottom:105px;*/
        overflow: auto;
    }
    .loader{
        z-index:999999;
    }
    button:focus {
        outline: initial;
    }
    .emp-notice-box{
        border-radius: 15px!important;
        .el-dialog__header{
            text-align: center;
        }
        .el-dialog__body {
            padding: 0 !important;
        }
    }
    table 
    {
        border-collapse: collapse;
    }
</style>
