var obj = {
    routers: [],
    set: function (res) {
        this.routers = res;
    },
    getAll: function () {
        return this.routers;
    },
    getByName: function (name)
    {
        return this.routers.filter(x => x.name === name);
    },
    getByCode: function (code)
    {
        return this.routers.filter(x => x.code === code);
    },
    getById: function (id)
    {
        return this.routers.find(x => x.id === id);
    },
    getByParentId: function (parentId)
    {
        return this.routers.filter(x => x.parentId === parentId);
    },
    getChildByCode: function (code) {
        var router = this.routers.find(x => x.code == code);
        if (!router) {
            layer.msg(`无效的路由code=${code}`)
            return null;
        }
        return this.routers.filter(x => x.parentId == router.id && x.isEnable ==1);
    },
    getChildsByCode: function (code) {
        var router = this.routers.find(x => x.code == code);
        if (!router) {
            layer.msg(`无效的路由code=${code}`)
            return null;
        }
        return this.routers.filter(x => x.parentId == router.id);
    },
    getChildByName: function (name) {
        var router = this.routers.find(x => x.name == name);
        if (!router) {
            layer.msg(`无效的路由name=${name}`)
            return null;
        }
        return this.routers.filter(x => x.parentId == router.id);
    },
    hasChild(parentCode, childCode)
    {
        var router = this.routers.find(x => x.code == parentCode);
        if (parentCode == childCode)
            return true;

        var childs = this.routers.filter(x => x.parentId == router.id && x.code == childCode && x.isEnable == 1);
        if (childs.length > 0)
            return true;
        else
            return false;
    }
};

export default {
    Router: obj
}