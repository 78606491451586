const modules = import.meta.glob('@/views/**/*.vue')  // 导入

function generate(data, prefixPath) {
    let routeResource = data;
    let routerPath = prefixPath;
    if (routeResource.path) {
        routerPath += routeResource.path;
    }
    else {
        routerPath += `/${routeResource.name}`;
    }
    // let routerPath = `${prefixPath}/${route.name}`;
    let componentPath = routeResource.component.replace('@/views/', '');
    
    if (!componentPath)
        return;
    
    componentPath = '@/views/' + componentPath;
    
    let router = {
        path: routerPath,
        name: routeResource.name,
        meta: {
            id: routeResource.id,
            code: routeResource.code,
            title: `${routeResource.title}`,
            subtitle: `${routeResource.subTitle}`,
            icon: `${routeResource.icon}`,
        },
        component: modules[componentPath.replace('@', '/src')],
        redirect: routeResource.redirect ? routerPath + routeResource.redirect : ''
    };
    
    return router;
}

function recursion(currRoute, res, depth) {
    currRoute.children = [];
    let children = res.filter(x => x.parentId == currRoute.meta.id);
    if (children.length > 0) {
        for (let z = 0; z < children.length; z++) {
            let child = children[z];
            let subRouter = generate(child, `${currRoute.path}`);
            if (!subRouter)
                continue;
            currRoute.children.push(subRouter);
            recursion(subRouter, res, ++depth);
        }
    }
}

export default {
    convert(res) {
        let routes = [];
        let parents = res.filter(x => x.parentId == 0);
        for (let i = 0; i < parents.length; i++) {
            let data = parents[i];
            let route = generate(data, `/:mchId`);
            if (!route)
                continue;
            
            routes.push(route);
            recursion(route, res, 0);
        }
        console.log('全局路由表：', routes);
        return routes;
    }
}