import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: {      //  用户信息
            id: '',
            name: '',
        },  
        merchant: {    //门店信息
            id: '',
        },
        isNavShow: 1,
        loaders: 0,
        
        isShowSettingNav: 1,            // 是否显示上方设置导航栏
        footerVisible: true,            // 底部菜单栏显示隐藏  
        
        merchantId: '',             // 门店id
        licenseNum: '',             // 车牌号
        shoppingCartInfo: {},       // 购物车详情
        
        isReloadFooter: false,      // 重置底部按钮层级
        
        isSettingTop: true,         // 商品设置是否显示头部
        isSettingFooter: true,      // 商品设置是否显示底部

        memberId: 0,    // 业务列表的车主id

        isFirstAddFreeGroup:true,//第一次默认加买套餐免单
        
        footerZIndex: 666,     // 代客下单商品页面的底部按钮层级
        
        bill:{
            id: 0,
            collapseProjectList: false,
            collapseVerifyCouponsBlock: false
        }
    },
    mutations: {
        setNavShow(state, val) {
            state.isNavShow = val;
        },
        setLoaders(state, val) {
            state.loaders = val;
        },
        
        setSettingNav(state, val){
            state.isShowSettingNav = val;
            state.footerVisible = val;
        },
        setFooterVisible(state, val) {
            state.footerVisible = val;
        },
        
        setShoppingCart(state, val){
            state.shoppingCartInfo = val;
        },
        
        setFooter(state, val){
            state.isReloadFooter = val;
        },
        
        setMemberId(state, val){
            state.memberId = val;
        },

        updateIsFirstAddFreeGroup(state, val) {
            state.isFirstAddFreeGroup = val;
        },
        
        updateFooterZIndex(state, val){
            state.footerZIndex = val;
        },
        
        setBillCollapseProjectList(state, val){
            
        }
    },
    actions: {

    },
    modules: {

    }
})
