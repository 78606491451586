import axios from "axios";


const showStatus = (status) => {
    let message = ''
    switch (status) {
        case 400:
            message = '请求错误(400)'
            break
        case 401:
            message = '未授权，请重新登录(401)'
            break
        case 403:
            message = '拒绝访问(403)'
            break
        case 404:
            message = '请求出错(404)'
            break
        case 408:
            message = '请求超时(408)'
            break
        case 500:
            message = '服务器错误(500)'
            break
        case 501:
            message = '服务未实现(501)'
            break
        case 502:
            message = '网络错误(502)'
            break
        case 503:
            message = '服务不可用(503)'
            break
        case 504:
            message = '网络超时(504)'
            break
        case 505:
            message = 'HTTP版本不受支持(505)'
            break
        default:
            message = `连接出错(${status})!`
    }
    return `${message}，请检查网络或联系管理员！`
}


export const $request = axios.create({
    baseURL: '',
    
    headers: {
        'x-requested-with': 'XMLHttpRequest',
        get: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        post: {
            'Content-Type': 'application/json;charset=utf-8/x-www-form-urlencoded'
        },
        postForm: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    },
    // 是否跨站点访问控制请求
    withCredentials: true,
    timeout: 200000,
    transformRequest: [(data) => {
        data = JSON.stringify(data)
        return data
    }],
    validateStatus() {
        // 使用async-await，处理reject情况较为繁琐，所以全部返回resolve，在业务代码中处理异常
        return true
    },
    transformResponse: [(data) => {
        if (typeof data === 'string' && data.startsWith('{')) {
            //data = handleResData(data)
            data = JSON.parse(data)
        }
        return data
    }]
    
})


// 请求拦截器
$request.interceptors.request.use((config) => {
    return config
}, (error) => {
    // 错误抛到业务代码
    error.data = {};
    error.data.msg = '服务器异常，请联系管理员！';
    return error
})

// 响应拦截器
$request.interceptors.response.use((response) => {
    const status = response.status
    let msg = ''
    if (status < 200 || status >= 300) {
        // 处理http错误，抛到业务代码
        msg = showStatus(status)
        if (typeof response.data === 'string') {
            response.data = {msg}
        }
        else {
            response.data.msg = msg
        }
    }
    //else if (status == 200) {
    //    response.data.result = JSON.parse(response.data.data)
    //}
    
    //console.log('response',response)
    return response
}, (error) => {
    // 错误抛到业务代码
    console.error(error)
    error.data = {};
    error.data.msg = '请求超时或服务器异常，请检查网络或联系管理员！';
    return error;
})

// 查找 / 第二次出现的位置 str：字符串对象，cha：要查找的字符，num：第i次出现的位置
function find(str, cha, num) {
    let x = str.indexOf(cha);
    for (let i = 0; i < num; i++) {
        x = str.indexOf(cha, x + 1);
    }
    return x;
}

let httpClass = {
    url: null,
    method: '', //字符串
    promise: null,
    
    buildUrl(url) {
        if (url.indexOf('/') === 0) {
            url = url.substr(1, url.length - 1);
        }
        let origin = window.location.origin;
        let pathname = window.location.pathname;
        let completeBasicUrl = '';
        if (pathname.lastIndexOf('/') > 0) {
            // let length = pathname.lastIndexOf('/');
            let length = find(pathname, '/', 1);
            completeBasicUrl = origin + pathname.substr(0, length) + '/';
        }
        else if (pathname.indexOf('/') === 0) {
            completeBasicUrl = origin + pathname + '/';
        }
        else {
            console.error('url无效！');
        }
        return completeBasicUrl + url;
        
    },
    
    /**
     * 设置请求参数
     * 如果是GET请求则会自动拼接在url后
     * 如果是POST请求则放入Body
     *
     * @param obj 参数对象，
     * @returns {httpClass}
     */
    setParameters(obj) {
        if (!obj)
            return this;
        this.parameters = obj;
        if (this.method === 'GET') {
            if (this.url.indexOf('?') < 0) {
                this.url += '?';
            }
            for (let paramKey of Object.keys(obj)) {
                let paramValue = obj[paramKey];
                if (this.url.indexOf('?') === this.url.length - 1) {
                    this.url += paramKey + '=' + paramValue;
                }
                else {
                    this.url += '&' + paramKey + '=' + paramValue;
                }
            }
        }
        return this;
    },
    
    /**
     * 注册请求成功回调函数
     *
     * @param callback(message, data) 成功回调函数
     *  message: 信息
     *  data: 数据
     *
     * @returns {httpClass}
     */
    success(callback) {
        this.successCallback = callback;
        return this;
    },
    
    /**
     * 注册请求失败回调函数
     *
     * @param callback(code, message, data) 失败回调函数
     *  code: 错误码
     *  message: 错误信息
     *  data: 错误信息携带的额外数据
     *
     * @returns {httpClass}
     */
    failed(callback) {
        this.failedCallback = callback;
        return this;
    },
    
    /**
     * 注册执行错误回调函数，一般由于回调函数中JS代码执行错误捕获到的异常导致
     *
     * @param callback(err) 错误回调函数
     *  err.message 错误信息
     *  err.stack 错误栈信息
     *
     * @returns {httpClass}
     */
    error(callback) {
        this.errorCallBack = callback;
        return this;
    },
    
    finally(finallyCallback) {
        this.finallyCallback = finallyCallback;
        return this;
    },
    
    /*
     * 发送请求
     * 
     * @returns void
     */
    request() {
        let that = this;
        if (this.method === 'GET') {
            this.promise = new Promise((resolve, reject) => {
                $request.get(this.url)
                    .then(res => {
                        if (res.status === 200 && res.data.code === 200) {
                            if (that.successCallback) {
                                that.successCallback(res.data.message, res.data.data, res);
                                resolve({
                                    message: res.data.message,
                                    data: res.data
                                });
                            }
                        }
                        else {
                            this.handlerFailed(res, reject);
                        }
                    })
                    .catch(err => {
                        console.error('axios catch exception', {
                            url: this.url,
                            err: err
                        });
                        if (that.errorCallBack) {
                            that.errorCallBack(err);
                            reject({
                                code: -1,
                                message: err.message || '请求错误',
                                data: err
                            })
                        }
                    })
                    .finally(() => {
                        if (that.finallyCallback)
                            that.finallyCallback();
                    });
            });
        }
        else if (this.method === 'POST') {
            this.promise = new Promise((resolve, reject) => {
                $request.post(this.url, this.parameters)
                    .then(res => {
                        if (res.status === 200 && res.data.code === 200) {
                            that.successCallback(res.data.message, res.data.data);
                            resolve({
                                code: -1,
                                message: res.data.message,
                                data: res.data
                            });
                        }
                        else {
                            this.handlerFailed(res, reject);
                        }
                    })
                    .catch(err => {
                        console.error('axios catch exception', {
                            url: this.url,
                            err: err
                        });
                        that.errorCallBack(err);
                        reject({
                            code: -1,
                            message: err.message || '请求错误',
                            data: err
                        })
                    })
                    .finally(() => {
                        if (that.finallyCallback)
                            that.finallyCallback();
                    });
            });
        }
        else {
            console.error('$http未实现该请求方式：' + this.method);
        }
        return this;
    },
    
    /**
     * 请求失败处理，外部请勿调用
     *
     * @param res
     */
    handlerFailed(res, reject) {
        console.error('http.failed[(' + res.headers['request-id'] + ')' + this.url + ']', res);
        let code = res.status;
        let failedMsg = '';
        if (res.data.code) {
            code = res.data.code;
        }
        if (res.data.message) {
            failedMsg = res.data.message;
        }
        else if (res.data.msg) {
            failedMsg = res.data.msg;
        }
        else {
            failedMsg = '请求出错，请稍后重试';
        }
        if (this.failedCallback) {
            this.failedCallback(code, failedMsg, res.data.data, res);
        }
    },
    
    /**
     * 获取请求的Promise
     * @returns
     * 成功：{message, data}
     * 失败：{code, message, data}
     */
    async() {
        return this.promise;
    }
};

export let http = {
    get: function (url) {
        let httpInstance = Object.create(httpClass);
        httpInstance.url = httpInstance.buildUrl(url);
        httpInstance.method = 'GET';
        return httpInstance;
    },
    
    post: function (url) {
        let httpInstance = Object.create(httpClass);
        httpInstance.url = httpInstance.buildUrl(url);
        httpInstance.method = 'POST';
        return httpInstance;
    }
};

// url跳转时携带门店ID
/*export let api = function(url){
    if(url.indexOf('/') === 0){
        url = url.substr(1,url.length - 1);
    }
    let origin = window.location.origin;
    let pathname = window.location.pathname;
    let completeBasicUrl = '';
    if (pathname.lastIndexOf('/') > 0) {
        let length = pathname.lastIndexOf('/');
        completeBasicUrl = origin + pathname.substr(0, length) + '/';
    }
    else if (pathname.indexOf('/') === 0) {
        completeBasicUrl = origin + pathname + '/';
    }
    else {
        console.error('url无效！');
    }
    return completeBasicUrl + url;
}*/

export let api = {
    build: function (url) {
        let pathname = window.location.pathname;
        console.log(window.location);
        if (pathname.indexOf('/') == 0) {
            pathname = pathname.substring(1);// 去掉相对路径中首位‘/’
        }
        
        var mchId = pathname;
        if (pathname.indexOf('/') > -1)
            mchId = pathname.substring(0, pathname.indexOf('/'));
        
        var reg = /^[1-9]\d*$/
        if (reg.test(mchId) == false) { //mchId 是否数字？
            throw url + "，API请求参数MchId不正确！";
        }
        
        if (url.indexOf('/') == 0)
            return '/' + mchId + url;
        else
            return '/' + mchId + '/' + url;
    },
    getMchLogoUrl: function (that, mchId) {
        var logo = "";
        var imgHost = that.$work_ctx.ossHost;
        if (imgHost.endsWith('/'))
            logo = imgHost + "image/mch_logo/" + mchId + ".png";
        else
            logo = imgHost + "/image/mch_logo/" + mchId + ".png";
        
        console.log('mch logo:', logo);
        return logo;
    },
    getMchQrCodeUrl: function (mchId) {
        var logo = "";
        var imgHost = that.$work_ctx.ossHost;
        if (imgHost.endsWith('/'))
            logo = imgHost + "image/mch_qrcode/" + mchId + ".png";
        else
            logo = imgHost + "/image/mch_qrcode/" + mchId + ".png";
        
        console.log('mch logo:', logo);
        return logo;
    }
}
